// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./omnes-regular-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./omnes_medium-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./omnes-semibold-webfont.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "/* #### Generated By: http://www.cufonfonts.com #### */\n\n@font-face {\n    font-family: 'Omnes Regular';\n    font-style: normal;\n    font-weight: normal;\n    font-display: auto;\n    src: local('Omnes Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n\n\n@font-face {\n    font-family: 'Omnes Medium';\n    font-style: normal;\n    font-weight: normal;\n    font-display: auto;\n    src: local('Omnes Medium'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n\n@font-face {\n    font-family: 'Omnes Semibold';\n    font-style: normal;\n    font-weight: normal;\n    font-display: auto;\n    src: local('Omnes Semibold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff');\n}", ""]);
// Exports
module.exports = exports;
